
  import {Component, Prop, Vue} from "vue-property-decorator";

  @Component({name:'AbCard'})
  export default class AbCard extends Vue {
    @Prop({required:true})
    title!:String;
  }


